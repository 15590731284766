import React from 'react';
import type { AppProps } from 'next/app';
import { setLocale } from 'yup';
import 'styles/globals.scss';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { setCookie } from 'cookies-next';

import { AuthProvider } from 'lib/auth/AuthContext';
import { MeetupProvider } from 'utils/MeetupContext';
import { StatusProvider } from 'utils/StatusContext';

setLocale({
  mixed: {
    default: 'field_invalid',
    required: 'Detta fält måste fyllas i',
  },
});

const BlippApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  const currentUrl = `https://blipp.se${router.asPath}`;

  const { ref } = router.query;
  if (ref !== undefined) {
    setCookie('ref', ref);
  }

  return (
    <AuthProvider>
      <MeetupProvider>
        <StatusProvider>
          <Head>
            <title>Blipp</title>
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-status-bar-style" content="black" />
            <meta name="mobile-web-app-title" content="Blipp App" />
            <link rel="dns-prefetch" href="https://bilder.blipp.se" />
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            <meta
              property="og:image"
              content="https://blipp.se/assets/images/og-image.jpg"
              key="og-image"
            />
            <meta property="og:image:type" content="image/jpg" key="og-image-type" />
            <meta property="og:type" content="website" />
            <meta
              property="og:image:alt"
              content="Äntligen en bättre bilaffär!"
              key="og-image-alt"
            />
            <meta property="og:url" content={currentUrl} key="ogurl" />
          </Head>
          <Component {...pageProps} />
          {process.env.NEXT_PUBLIC_GTM_ID && (
            <Script
              id="tag-manager"
              dangerouslySetInnerHTML={{
                __html: `
                                    (function(w, d, s, l, i) {
                                        w[l] = w[l] || [];
                                        w[l].push({
                                            'gtm.start': new Date().getTime(),
                                            event: 'gtm.js'
                                        });
                                        var f = d.getElementsByTagName(s)[0],
                                            j = d.createElement(s),
                                            dl = l != 'dataLayer' ? '&l=' + l : '';
                                        j.async = true;
                                        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                                        f.parentNode.insertBefore(j, f);
                                    })(window, document, 'script', 'dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
                                    `,
              }}
              strategy="afterInteractive"
              defer
            />
          )}
          <Script
            src="//cdn.cookietractor.com/cookietractor.js"
            data-lang="sv-SE"
            data-id="600afeef-0867-4764-87d8-6ba8ce334634"
          />
          <Script
            strategy="lazyOnload"
            defer
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGJE_Lz_iwf0HryVGFfd0PcRqivq8df_Y&libraries=places&languages=se&callback=Function.prototype"
          />
          <Script
            id="trustpilot"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            strategy="afterInteractive"
            defer
          />
        </StatusProvider>
      </MeetupProvider>
    </AuthProvider>
  );
};

export default BlippApp;
